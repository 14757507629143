export const featuresData = [
  {
    id: "1",
    icon: "/images/icons/features/speaks-your-lg.svg",
    title: "Speaks your language",
    desc:
      "CodeWell AI assistants can be trained to speak any language in the world.",
  },
  {
    id: "2",
    icon: "/images/icons/features/high-precision.svg",
    title: "High Precision Rate > 95%",
    desc:
      "With our proprietary NLP and NLU technology our assistants can speak and understand any language on a human level.",
  },
  {
    id: "3",
    icon: "/images/icons/features/dialects-and-street-talk.svg",
    title: "Understands Dialects and Street talk",
    desc:
      "The assistants built with Codewell AI are capable of understanding multiple dialects per given language including jargon and street talk.",
  },
  {
    id: "4",
    icon: "/images/icons/features/multiple-languages.svg",
    title: "Multiple languages at the same time",
    desc:
      "Our assistants can be trained to speak multiple languages at the same time as well as support multiple letterings per given language.",
  },
  {
    id: "5",
    icon: "/images/icons/features/recognizes-context.svg",
    title: "Recognizes context and follow-up questions",
    desc:
      "CodeWell AI’s proprietary content extraction technology enables our assistants to understand the context of the conversation and be able to answer any follow-up questions that the customers might have.",
  },
  {
    id: "6",
    icon: "/images/icons/features/offensive-language-handling.svg",
    title: "Offensive language handling",
    desc:
      "No more F@*K, $#!T, C#%T!.. Based on the context our assistants can calm the user down and get the conversation right on track.",
  },
  {
    id: "7",
    icon: "/images/icons/features/multiline-questions.svg",
    title: "Handles multi-line questions",
    desc:
      "There are two types of people in the world – those who hit the enter key after asking a question, those who press enter on every second word. Our assistants efficiently understand every customer regardless of his typing style.",
  },
  {
    id: "8",
    icon: "/images/icons/features/human-agent.svg",
    title: "Seamless transfer to a human agent",
    desc:
      "Our assistants can transfer the conversation to a human agent within same conversational window seamlessly. However, this is rarely the case as 99% percent of all conversations are successfully handled by our assistants.",
  },
];

export const platformsData = [
  {
    id: "1",
    image: "/images/platforms/messenger.svg",
    title: "messenger",
  },
  {
    id: "2",
    image: "/images/platforms/slack.svg",
    title: "slack",
  },
  {
    id: "3",
    image: "/images/platforms/whatsapp.svg",
    title: "whatsapp",
  },
  {
    id: "4",
    image: "/images/platforms/viber.svg",
    title: "viber",
  },
  {
    id: "5",
    image: "/images/platforms/telegram.svg",
    title: "telegram",
  },
  {
    id: "6",
    image: "/images/platforms/skype.svg",
    title: "skype",
  },
  {
    id: "7",
    image: "/images/platforms/apple.svg",
    title: "apple",
  },
  {
    id: "8",
    image: "/images/platforms/android.svg",
    title: "android",
  },
  {
    id: "9",
    image: "/images/platforms/web.svg",
    title: "web",
  },
];

export const codewellChatbotData = [
  {
    id: "1",
    title: "Trained by Data Scientists and AI Engineers",
  },
  {
    id: "2",
    title: "NLP and NLU for any language",
  },
  {
    id: "3",
    title: "Domain-specific knowledge",
  },
  {
    id: "4",
    title: "Multilingual and alphabet support",
  },
  {
    id: "5",
    title: "High precision rate",
  },
  {
    id: "6",
    title: "Natural conversational experience",
  },
  {
    id: "7",
    title: "Seamless fallback to a real agent",
  },
  {
    id: "8",
    title: "Platform/vendor independence",
  },
];

export const botBuildersData = [
  {
    id: "1",
    title: "Trained manually by the Client",
  },
  {
    id: "2",
    title: "Natural Language Processing limited to English language",
  },
  {
    id: "3",
    title: "Rule-based systems (low level of AI)",
  },
  {
    id: "4",
    title: "Time consuming manual data entry for training",
  },
  {
    id: "5",
    title: "High inaccuracy and misunderstanding",
  },
  {
    id: "6",
    title: "Non-human “Robotic” conversational experience",
  },
  {
    id: "7",
    title: "Predefined set of possible integrations",
  },
  {
    id: "8",
    title: "Limited set of possibilities",
  },
];

export const digitalizationData = [
  {
    id: "1",
    year: "1970",
    image: "/images/icons/personal-computers.svg",
    title: "Personal computers",
  },
  {
    id: "2",
    year: "1982",
    image: "/images/icons/ivr.svg",
    title: "IVR",
  },
  {
    id: "3",
    year: "1999",
    image: "/images/icons/www.svg",
    title: "WEB APPLICATIONS",
  },
  {
    id: "4",
    year: "2012",
    image: "/images/icons/mobile-apps.svg",
    title: "Mobile Apps",
  },
  {
    id: "5",
    year: "Today",
    image: "/images/icons/conversation.svg",
    title: "CONVER. AI",
  },
];

// Expiriences Data

export const expiriencesData = [
  {
    id: "1",
    icon: "/images/icons/telecom.svg",
    title: "Telecom",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
    link: "/slavcho",
  },
  {
    id: "2",
    icon: "/images/icons/banking.svg",
    title: "Banking",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "3",
    icon: "/images/icons/healthcare.svg",
    title: "Healthcare",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "4",
    icon: "/images/icons/eCommerce.svg",
    title: "eCommerce",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
];

// Benefits Data

export const perfectForEveryone = [
  {
    id: "1",
    icon: "/images/icons/perfect-for-everyone/icon-1.svg",
    title: "Covering both tech savvy and non-technical users",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "2",
    icon: "/images/icons/perfect-for-everyone/icon-2.svg",
    title: "NATURAL CONVERSATION THROUGH CHAT, SO NO LEARNING CURVE INVOLVED",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "3",
    icon: "/images/icons/perfect-for-everyone/icon-3.svg",
    title: "Fast and easy on-board process",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
];

export const availableRoundTheClock = [
  {
    id: "1",
    icon: "/images/icons/available-round-the-clock/icon-1.svg",
    title: "Available 24/7 for conversation",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "2",
    icon: "/images/icons/available-round-the-clock/icon-2.svg",
    title: "Serving unlimited number of customers at the same time",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "3",
    icon: "/images/icons/available-round-the-clock/icon-3.svg",
    title: "No waiting and no unnecessary transfers",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
];

export const automateDepartments = [
  {
    id: "1",
    icon: "/images/icons/automate-departments/icon-1.svg",
    title: "Direct access to new channels (Facebook,whatsapp,viber ..)",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "2",
    icon: "/images/icons/automate-departments/icon-2.svg",
    title: "Reducing the cost by automating entire departments with ai",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "3",
    icon: "/images/icons/automate-departments/icon-3.svg",
    title:
      "Providing personalized customer experience thus improved customer satisfaction",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
  {
    id: "4",
    icon: "/images/icons/automate-departments/icon-4.svg",
    title: "Driving sales and revenue up by focusing on delivering value",
    desc:
      "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Porta lectus sit neque ut pulvinar sodales mauris. ",
  },
];
