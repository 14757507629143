import React from "react";

// Components
import Layout from "../components/Layout";
import SEO from "../components/SEO";

import HomeHero from "../components/homePage/hero";
import Platforms from "../components/homePage/platforms";
import Features from "../components/homePage/features";
import AoneTelekom from "../components/homePage/AoneTelekom";
import Digitalization from "../components/homePage/digitalization";
import ChatbotDifference from "../components/homePage/chatbotDifference";
import Experiences from "../components/homePage/experiences";
import Benefits from "../components/homePage/benefits";
import Testimonial from "../components/slavchoPage/testimonial";
import Cta from "../components/Cta";
import { AnimatePresence, motion } from "framer-motion"

const transition = { duration: 1, ease: [0.6, -0.05, 0.01, 0.9] }

const Home = () => {
  return (
    <AnimatePresence
      exitBeforeEnter
      initial={{ opacity: 0 }}
      exit={{ opacity: 0 }}
      animate={{ opacity: 1 }}
    >
      <Layout>

        <SEO title="Home | Codewell AI" />
        {/* <SEO title="Home Page" description="custom description" /> */}
        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={transition}>
          <HomeHero />
          <Features />
          <Platforms />
          <AoneTelekom />

          <Benefits />
          <ChatbotDifference />
          <Experiences />
          <Digitalization />
          <Testimonial showButton />
          <Cta />

        </motion.div>

      </Layout>
    </AnimatePresence>
  )
};
export default Home;

