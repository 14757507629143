
import styled from "styled-components";
import { H6 } from "./styles/TextStyles";
import { motion } from "framer-motion";
import React from "react";


// function to handle the click
const handleClick = () => {
  const appointletScheduler = document.querySelector('.appointlet-scheduler');
  // const appointletShade = document.querySelector('.appointlet-shade');
  appointletScheduler.style.display = 'block';
  // appointletShade.style.display = 'block';
}

const Button = (props) => {
  return (
    <>
      <ButtonComponent
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        data-appointlet-organization="codewell"
        data-appointlet-service="327650"
        onClick={handleClick}
      >
        <ButtonText>{props.text}</ButtonText>
      </ButtonComponent>


    </>
  )
}






export default Button;

const ButtonComponent = styled(motion.button)`
  background-color: #aed9aa;
  border: 0;
  padding: 2rem 4.5rem;
  cursor: pointer;
  border-radius: 1rem;
`;

const ButtonText = styled(H6)`
  color: #1f3639;
`;
