import React from "react";
import styled from "styled-components";
// import { motion } from "framer-motion"

import { H2, Gradient } from "../styles/TextStyles";
import { Container } from "../../styles/globalStyles";
// import { digitalizationData } from "../../data/homePageData";

const a1Video = () => {
  return (
    <A1VideoSection>
      <Container inner>
        <Title>
          See how
           <img
            src="/images/a1-telekom-group@2x.png"
            alt="Borche Gjorgjeski rakovoditel vo kontakt centarot na a1 makedonija"
          /> <Gradient>automates 73%</Gradient> of all online interactions with CodeWell AI
           </Title>

        <Wrapper>
          <IframeVideoWrapper>
            <iframe width="100%" height="auto" src="https://www.youtube.com/embed/ptYzTZB8v9g" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </IframeVideoWrapper>

        </Wrapper>
      </Container>
    </A1VideoSection>
  );
};

export default a1Video;



const A1VideoSection = styled.section`
  padding-bottom: 16rem;
`;

// Title
const Title = styled(H2)`
  margin-bottom: 6rem;

  img{
    
    display: inline-block;
    position:relative;
    height:80px;
    bottom:-8px;
    left:8px;
    /* margin-left:4px; */
    
  }
`;

const Wrapper = styled.div`

`

const IframeVideoWrapper = styled.div`
  position: relative;
  padding-top:50%;

  iframe{
    position: absolute;
    top:0;
    height:100%;
    width:100%;
  }
`

// const DigiWrapper = styled.div`
//   position: relative;
//   display: grid;
//   grid-template-columns: repeat(5, 1fr);
//   grid-column-gap: 4rem;

//   @media ${device.tabletL} {
//     grid-column-gap: 2rem;
//   }

//   @media ${device.tablet} {
//     grid-template-columns:1fr;
//     grid-row-gap: 2rem;
//   }

//   & {
//     :nth-of-type(1) {
//       margin-bottom: 4rem;

//       @media ${device.tablet} {
//         margin-bottom: 0;
//       }
//     }


//   }
// `;

// const YearTitle = styled(H5)`
//   text-transform: uppercase;
//   text-align: center;
//   margin-bottom: 1rem;



//   @media ${device.tablet} {
//     margin-bottom: 0;
//     margin-left:1rem;
//   }
// `;

