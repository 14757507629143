import { useStaticQuery } from "gatsby";
import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";

const query = graphql`
  {
    site {
      siteMetadata {
        author
        siteDesc: description
        image
        siteUrl
        siteTitle: title
        twitterUsername
      }
    }
  }
`;

const SEO = ({ title, description }) => {
  const { site } = useStaticQuery(query);
  const {
    siteDesc,
    siteTitle,
    siteUrl,
    image,
    twitterUsername,
  } = site.siteMetadata;

  return (
    <Helmet htmlAttributes={{ lang: "eng" }} title={title || siteTitle}>
      <meta name="description" content={description || siteDesc} />
      <meta name="image" content={image} />

      {/* Facebook */}
      <meta property="og:title" content={siteTitle} />
      <meta property="og:url" content={siteUrl} />
      <meta property="og:description" content={siteDesc} />
      <meta property="og:image" content={`${siteUrl}${image}`} />

      {/* Twitter */}
      <meta twitter="twitter:title" content={siteTitle} />
      <meta twitter="twitter:url" content={siteUrl} />
      <meta twitter="twitter:creator" content={twitterUsername} />
      <meta twitter="twitter:description" content={siteDesc} />
      <meta twitter="twitter:image" content={`${siteUrl}${image}`} />

      {/* <script type="text/javascript" src="https://static.apester.com/js/sdk/latest/apester-sdk.js" async /> */}

      <script src="https://www.appointletcdn.com/loader/loader.min.js"
        async
        defer />
    </Helmet>
  );
};

export default SEO;
