import React from "react";
import styled from "styled-components";
import { H2, H5, P } from "../styles/TextStyles";
import { Container, device } from "../../styles/globalStyles";
import { featuresData } from "../../data/homePageData";

const Features = () => {
  return (
    <FeaturesSection>
      <Container inner>
        <Subtitle uppercase green>Top Features</Subtitle>
        <Title>Conversational AI tailored to your needs</Title>
        <Wrapper>
          {featuresData.map((item, index) => (
            <Feature key={index} item={item}>
              <Icon>
                <img src={item.icon} alt={item.title} />
              </Icon>
              <div>
                <FeatureTitle uppercase>{item.title}</FeatureTitle>
                <Description small>{item.desc}</Description>
              </div>
            </Feature>
          ))}
        </Wrapper>
      </Container>
    </FeaturesSection>
  );
};

export default Features;

const Subtitle = styled(H5)`
  text-align: center;
  margin: 0 auto;
  margin-bottom:2rem;
`;
const Title = styled(H2)`
  max-width: 68rem;
  text-align: center;
  margin: 0 auto;
`;
const FeaturesSection = styled.section``;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;
  grid-row-gap: 6rem;
  max-width: 84rem;
  margin: 0 auto;
  margin-top: 8rem;

  @media ${device.tablet} {
    grid-template-columns: 1fr;
    justify-items:center;
    p{
      max-width:40rem;
    }
  }
`;

const Feature = styled.div`
  display: grid;
  grid-template-columns: 7rem auto;
  grid-column-gap: 1.6rem;

  @media ${device.mobileL} {
    grid-template-columns: 5.6rem auto;
  }
  
`;

const FeatureTitle = styled(H5)`
  margin-bottom: 0.4rem;
  max-width: 25rem;
  @media ${device.mobileL} {
    max-width: 100%;
    margin-bottom: 0.8rem;
      }
`;

const Description = styled(P)``;

const Icon = styled.div`
  height: auto;
  width: 100%;
`;
