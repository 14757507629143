import React from "react";
import styled from "styled-components";
// import { Link } from "gatsby";
import { H2, H4, P } from "../styles/TextStyles";
import { Container, device } from "../../styles/globalStyles";
import { expiriencesData } from "../../data/homePageData";

const Experiences = () => {
  return (
    <ExperiencesSection>
      <Container inner>
        <Title>We automate customer service and sales departments</Title>
        <Subtitle large>
          Right now, our product is being used in the following industries
        </Subtitle>
        <Wrapper>
          {expiriencesData.map((item, index) =>
          (
            <ExpItem item={item} key={index}>
              <Icon src={item.icon} alt={item.title} />
              <Content>
                <ExpTitle>{item.title}</ExpTitle>
                {/* <Description>{item.desc}</Description> */}
              </Content>
            </ExpItem>
          )
          )}
          {/* {expiriencesData.map((item, index) =>
            item.link ? (
              <Link
                item={item}
                key={index}
                to={item.link}
                className="experiences-link"
              >
                <ExpItem>
                  <Icon src={item.icon} alt={item.title} />
                  <Content>
                    <ExpTitle>{item.title}</ExpTitle>
                    <Description>{item.desc}</Description>
                  </Content>
                </ExpItem>
              </Link>
            ) : (
                <ExpItem item={item} key={index}>
                  <Icon src={item.icon} alt={item.title} />
                  <Content>
                    <ExpTitle>{item.title}</ExpTitle>
                    <Description>{item.desc}</Description>
                  </Content>
                </ExpItem>
              )
          )} */}
        </Wrapper>
      </Container>
    </ExperiencesSection>
  );
};

export default Experiences;

const Title = styled(H2)`
  margin-bottom: 2.4rem;
  max-width:85rem;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
  @media ${device.mobileM} {
    text-align:left;
  }
`;
const Subtitle = styled(P)`
text-align:center;
  margin-left:auto;
  margin-right:auto;
  @media ${device.mobileM} {
    text-align:left;
  }
  `

  ;

const ExperiencesSection = styled.section`
  padding-bottom: 16rem;
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 8rem;
  margin-top: 6rem;
  max-width:80rem;
  margin-left:auto;
  margin-right:auto;

  @media ${device.tabletL} {
    grid-gap: 4rem;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr 1fr;

    p{
      max-width:50rem;
    }
  }

  @media ${device.mobileM} {
    grid-gap: 2rem;
  }

  .experiences-link {
    color: unset;
    border-radius: 1rem;
    transition: all 0.25s ease;

    &:hover {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }
`;

const ExpItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 2rem;
  /* padding: 2rem; */
  align-items:center;
  justify-self:center;

  @media ${device.mobileL} {
    grid-template-columns: 6rem auto;
    grid-column-gap: 2rem;
    padding: 1.6rem;
  }
  @media ${device.mobileM} {
    grid-template-columns: 4.8rem auto;
    grid-column-gap: 1.6rem;
  }
`;

const Icon = styled.img`
  margin-left:auto;
  margin-right:auto;
`;


const Content = styled.div``;

const ExpTitle = styled(H4)`
  /* margin-bottom: 1rem; */
`;

// const Description = styled(P)``;
