import React from "react";
import styled, { css, keyframes } from "styled-components";

import { H2, H4, H6, P, Gradient } from "../styles/TextStyles";
import { Container, device } from "../../styles/globalStyles";
import { codewellChatbotData, botBuildersData } from "../../data/homePageData";

const ChatbotDifference = () => {
  return (
    <ChatbotDifferenceSection>
      <Container inner>
        <Title>
          We create Conversational AI assistants <Gradient>not chatbots!</Gradient>
        </Title>

        <Subtitle large>
          To unlock the potential of the conversational channels chatting with an AI assistant should feel like talking to a real human being. Our assistants can communicate on a human level and automate processes such as sales, care and advice. Think about the perfect human assistant in a form of an AI-representative that can scale on demand and is available 24/7 for your customers.
        </Subtitle>

        <Wrapper>
          <Card codewell>
            <CardSubTitle>DIGITAL ASSISTANTS MADE WITH</CardSubTitle>
            <CardTitle>CodeWell AI Technology</CardTitle>

            <CardList>
              {codewellChatbotData.map((item, index) => (
                <CardListItem item={item} key={index}>
                  <img src="/images/icons/checkmark.svg" alt="{item.title}" />
                  <CardListItemTitle>{item.title}</CardListItemTitle>
                </CardListItem>
              ))}
            </CardList>
          </Card>

          <Card>
            <CardSubTitle>BOT MADE WITH</CardSubTitle>
            <CardTitle>Bot Builders / Frameworks</CardTitle>
            <CardList>
              {botBuildersData.map((item, index) => (
                <CardListItem item={item} key={index}>
                  <img
                    src="/images/icons/chevron-right.svg"
                    alt="{item.title}"
                  />
                  <CardListItemTitle>{item.title}</CardListItemTitle>
                </CardListItem>
              ))}
            </CardList>
          </Card>
        </Wrapper>

        <LinkText href="https://venturebeat.com/2017/06/17/the-good-the-bad-and-the-ugly-of-chatbots/" target="_blank">
          ** 73% of users churn due to a bad Chatbot experience and
          conversational inaccuracy.You have only 1 shot at making it right.
        </LinkText>
        <LinkText href="https://www.theverge.com/2019/3/5/18251326/ai-startups-europe-fake-40-percent-mmc-report?fbclid=IwAR2NV3FdSizJkus0gZ2j-2DICYgZOZCFAxQftMF8z9CaXNGXbwkc2oZ8jzs" target="_blank">** 40% of “AI Companies” in Europe don’t accutally use AI.</LinkText>
      </Container>
    </ChatbotDifferenceSection>
  );
};

export default ChatbotDifference;

const animationShape = keyframes`
  0% { transform: translate(-50%,-50%);}
  /* 25% { transform: translate(-40%,-50%);} */
  50% { transform: translate(-40%,-40%);}
  75% { transform: translate(-60%,-40%);}
  100% { transform: translate(-50%,-50%);}
`;

const Title = styled(H2)`
  max-width: 72rem;
  margin-bottom: 2rem;
  text-align:center;
  margin-left:auto;
  margin-right:auto;

  @media ${device.tabletL} {
    text-align:left;
  }
`;

const Subtitle = styled(P)`
  max-width: 82rem;
  text-align:center;
  margin-left:auto;
  margin-right:auto;
  @media ${device.tabletL} {
    text-align:left;
  }
`;

const ChatbotDifferenceSection = styled.section`
  padding-bottom: 16rem;
`;
const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 11rem;
  align-items: center;
  margin-top: 4rem;
  margin-bottom: 2.4rem;

  @media ${device.tabletL} {
    grid-column-gap: 8rem;
  }
  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }
`;

const Card = styled.div`
  ${(props) =>
    props.codewell &&
    css`
      position: relative;
      padding: 4rem;
      background-color: rgba(255, 255, 255, 0.05);
      box-shadow: inset 0px 0px 2px rgba(255, 255, 255, 0.5);
      border-radius: 1.6rem;
      backdrop-filter: blur (40px);
      -webkit-backdrop-filter: blur (40px);
      z-index: 10;
      @media ${device.tablet} {
        margin-bottom:4rem;
      }
     @media ${device.mobileM} {
      padding: 2.4rem;
     }


      &:before {
        position: absolute;
        content: "";
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        height: 30rem;
        width: 30rem;
        border-radius: 50%;
        background-color: #aed9aa;
        filter: blur(80px);
        opacity: 0.5;
        z-index: -1;
        animation: ${animationShape} 10s 0s infinite;
      }
    `}
`;

const CardSubTitle = styled(H6)`
  margin-bottom: 0.8rem;
  opacity: 0.5;
`;

const CardTitle = styled(H4)`
  text-transform: uppercase;
  margin-bottom: 3.2rem;
`;

const CardList = styled.ul``;

const CardListItem = styled.li`
  display: grid;
  grid-template-columns: 2.8rem auto;
  grid-column-gap: 1rem;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 2rem;
  }
`;

const CardListItemTitle = styled(H6)`
  text-transform: uppercase;
`;

const LinkText = styled.a`
  display: block;
  text-decoration:none;
  color:#aed9aa;
  font-size:1.3rem;
  text-align:center;
  /* max-width: 55rem; */

  @media ${device.tabletL} {
    text-align:left;
  }
`;
