import React from "react"
import styled from 'styled-components'
import { device } from "../styles/globalStyles";
import { AnimatePresence, motion } from "framer-motion"


const backdrop = {
    visible: { opacity: 1 },
    hidden: { opacity: 0 }
}
const modalcontent = {
    hidden: {
        y: "5vh",
        opacity: 0
    },
    visible: {
        y: "0",
        opacity: 1,
        transition: { delay: 0.5 }
    }

}

const Modal = ({ handleClose, isOpen }) => {

    return (
        <AnimatePresence exitBeforeEnter>
            {isOpen && (
                <Overlay
                    // onClick={handleClose} // Close Modal Outside From Overlay
                    variants={backdrop}
                    initial="hidden"
                    animate="visible"
                    exit="hidden"
                >
                    <ModalContent variants={modalcontent}>
                        {/* <Video
                            autoPlay
                            controls
                        >
                            <source src="/video/test-video.mp4"></source>

                        </Video> */}

                        <IframeVideo src="https://www.youtube.com/embed/1a1TZ0hQ7KE" title="YouTube video player" frameborder="0" allow="autoplay" allowfullscreen autoplay></IframeVideo>
                        <CloseButton
                            onClick={handleClose}
                            whileHover={{ scale: 1.1 }}
                            whileTap={{ scale: 0.9 }}
                        >
                            <CloseButtonIcon src="/images/icons/close.svg" alt="close" />
                        </CloseButton>
                    </ModalContent>

                </Overlay>
            )}
        </AnimatePresence>
    )

}


export default Modal


const Overlay = styled(motion.div)`
display:flex;
justify-content:center;
align-items:center;
position:fixed;
top:0;
left:0;
width:100vw;
height:100vh;
background-color:rgba(0,0,0,0.8);
backdrop-filter:blur (150px);
-webkit-backdrop-filter:blur (150px);
z-index:150;
`

const ModalContent = styled(motion.div)`
position:relative;
background-color:#fff;
width:70vw;
height:600px;
max-width:106rem;
border:0px;
padding:4px;

@media ${device.tabletL} {
    width:calc(100vw - 4rem);
    height:auto;
}

`

const CloseButton = styled(motion.button)`
    display:flex;
    justify-content:center;
    align-items:center;
    position:absolute;
    top:-1rem;
    right:-2rem;
    width:4.8rem;
    height:4.8rem;
    background-color:#fff;
    border-radius:50%;
    border:none;
    cursor: pointer;
    @media ${device.mobileL} {
        width:4rem;
        height:4rem;
        top:-1rem;
    right:-1rem;
}

`
const CloseButtonIcon = styled.img`
    height:2rem;
    width:auto;
`


// const Video = styled(motion.video)`
//     width:100%;
//     height:100%;
//     object-fit:cover;
//     object-position:center;
//     box-sizing:border-box;
//     border:0px solid #fff;
// `

const IframeVideo = styled(motion.iframe)`
    width:100%;
    height:100%;
    /* object-fit:cover;
    object-position:center; */
    /* box-sizing:border-box; */
    border:0px solid #fff;
`


