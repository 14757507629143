import React from "react";
import styled from "styled-components";
import { Gradient, H2, H5, P } from "../styles/TextStyles";
import { Container, device, Icon } from "../../styles/globalStyles";
import {
  perfectForEveryone,
  availableRoundTheClock,
  automateDepartments,
} from "../../data/homePageData";

const Benefits = () => {
  return (
    <BenefitsSection>
      <Container inner>
        <Row>
          <Content>
            <Title>
              Perfect for <Gradient>everyone</Gradient>
            </Title>
            <Paragraph large>
              With our digital assistants people chat in the same manner they chat with their friends and family, there is no learning curve and because of this, they are equally great for both tech-savvy and non-technical users.
            </Paragraph>
          </Content>

          <List>
            {perfectForEveryone.map((item, index) => (
              <Item key={index} item={item}>
                <Icon><img src={item.icon} alt={item.title} /></Icon>
                <Content>
                  <ItemTitle uppercase>{item.title}</ItemTitle>
                </Content>
              </Item>
            ))}
          </List>
        </Row>

        <Row>
          <Content>
            <Title>
              Available round <Gradient>the clock</Gradient>
            </Title>
            <Paragraph large>
              Customers can be served instantly, at any time of the day, and have the conversation based on their own tempo and agenda.
            </Paragraph>
          </Content>

          <List>
            {availableRoundTheClock.map((item, index) => (
              <Item key={index} item={item}>
                <Icon><img src={item.icon} alt={item.title} /></Icon>
                <Content>
                  <ItemTitle uppercase>{item.title}</ItemTitle>
                </Content>
              </Item>
            ))}
          </List>
        </Row>

        <Row>
          <Content>
            <Title>
              Automate<Gradient>Departments</Gradient>
            </Title>
            <Paragraph large>
              We help companies automate entire departments through AI assistants, reducing costs, driving sales up while at the same time improving customer satisfaction.
            </Paragraph>
          </Content>

          <List>
            {automateDepartments.map((item, index) => (
              <Item key={index} item={item}>
                <Icon><img src={item.icon} alt={item.title} /></Icon>
                <Content>
                  <ItemTitle uppercase>{item.title}</ItemTitle>
                </Content>
              </Item>
            ))}
          </List>
        </Row>
      </Container>
    </BenefitsSection>
  );
};
export default Benefits;

const BenefitsSection = styled.section`
  padding-bottom: 16rem;
`;

const Content = styled.div``;

const Item = styled.div`
  display: grid;
  grid-template-columns: 7rem auto;
  grid-column-gap: 2rem;
  align-items: center;
  margin-bottom: 4rem;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 4rem;

  ${Content} {

    @media ${device.tablet} {
      order:1;
    }

    &:nth-of-type(1) {
      padding-right: 9rem;
      @media ${device.tablet} {
        padding-right: unset;
     }

    }
  }

  @media ${device.tablet} {
    grid-template-columns: 1fr;
  }

  &:nth-of-type(2) {
    margin: 16rem 0;
    @media ${device.tablet} {
      margin: 10rem 0;
    }
    @media ${device.mobileL} {
      margin: 6rem 0;
    }


    ${Content} {
      &:nth-of-type(1) {
        order: 2;
        padding-left: 4rem;
        padding-right: 0;

        @media ${device.tablet} {
          padding-left: 0;
    }
      }
    }
  }
`;

const Title = styled(H2)`
  margin-bottom: 2.4rem;
`;

const Paragraph = styled(P)``;
const List = styled.div`
  ${Content} {
    max-width: 40rem;
  }

  @media ${device.tablet} {
      margin-top:4rem;
      order:2;
    }
`;

const ItemTitle = styled(H5)``;
