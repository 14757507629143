import React from "react";
import styled, { keyframes } from "styled-components";

import { Container, device } from "../../styles/globalStyles";

import { H3, P } from "../../components/styles/TextStyles";
import { platformsData } from "../../data/homePageData";

const Platforms = () => {
  return (
    <PlatformsSection>
      <ShapeWrapper>
        <Shape />
        <Shape />
        <Shape />
      </ShapeWrapper>

      <Container>
        <Wrapper>
          <Title>
            Integration with all of your favorite tools & platforms.
          </Title>
          <PlatformsList count={platformsData.length}>
            {platformsData.map((item, index) => (
              <PlatformsListItem item={item} key={index}>
                <img src={item.image} alt={item.title} />
              </PlatformsListItem>
            ))}
          </PlatformsList>
          <Subtitle>
            and basically any platform that supports chatbots.
          </Subtitle>
        </Wrapper>
      </Container>
    </PlatformsSection>
  );
};

export default Platforms;

const animationOne = keyframes`
  0% { transform: translate(0%,0%);}
  25% { transform: translate(20%,20%);}
  50% { transform: translate(20%,-20%);}
  75% { transform: translate(0%,-20%);}
  100% { transform: translate(0%,0%);}
`;

const animationTwo = keyframes`
   0% { transform: translate(0%,0%);}
  25% { transform: translate(-20%,-20%);}
  50% { transform: translate(-30%,20%);}
  75% { transform: translate(0%,20%);}
  100% { transform: translate(0%,0%);}
`;

const animationThree = keyframes`
  0% { transform: translate(0%,0%);}
  25% { transform: translate(-20%,-20%);}
  50% { transform: translate(-20%,20%);}
  75% { transform: translate(0%,20%);}
  100% { transform: translate(0%,0%);}
`;

const PlatformsSection = styled.section`
  padding: 16rem 0;
  position: relative;
  width: 100%;
  overflow:hidden;
`;

const Title = styled(H3)`
  max-width: 54rem;
  text-align: center;
  margin: 0 auto;
`;
const Subtitle = styled(P)`
  max-width: 63rem;
  text-align: center;
  margin: 0 auto;
`;

const Wrapper = styled.div`
  position: relative;
  max-width: 106rem;
  margin: 0 auto;
  padding: 4rem;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: inset 0px 0px 2px rgba(255, 255, 255, 0.5);
  border-radius: 1.6rem;
  backdrop-filter: blur (40px);
  -webkit-backdrop-filter: blur (40px);
  z-index: 10;

  @media ${device.tablet} {
    padding: 2.4rem;
  }
`;

const ShapeWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 15vh;
  z-index: 1;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  max-width: 60rem;
  margin: 0 auto;

  z-index: 1;

  > * {
    position: absolute;
    display: block;
    height: 20vh;
    width: 20vh;
    top: 0%;
    opacity: 0.7;
    border-radius: 50%;
    filter: blur(8rem);

    :nth-child(1) {
      left: 10%;
      background-color: #aed9aa;
      animation: ${animationOne} 10s 0s infinite;
    }

    :nth-child(2) {
      left: 40%;
      background-color: #5bb399;
      animation: ${animationTwo} 10s 0s infinite;
    }
    :nth-child(3) {
      left: 70%;
      background-color: #4e878e;
      animation: ${animationThree} 10s 0s infinite;
    }
  }
  /* opacity:.2; */
`;

const Shape = styled.div``;

const PlatformsList = styled.div`
  display: grid;
  grid-template-columns: repeat(${(props) => props.count}, auto);
  justify-content:center;
  grid-gap: 3rem;
  margin: 4rem 0;

  @media ${device.tablet} {
    display:flex;
    flex-wrap:wrap;
    justify-content:center;
  }
`;
const PlatformsListItem = styled.div`
  justify-self: center;
  align-self: center;
`;
