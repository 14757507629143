import React from "react";

export const Checkmark = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.5">
      <circle cx="8" cy="8" r="8" fill="#DCE7E8" />
      <path
        d="M6.28572 10.3829L3.90286 8.00002L3.09143 8.80573L6.28572 12L13.1429 5.14287L12.3371 4.33716L6.28572 10.3829Z"
        fill="#1F3639"
      />
    </g>
  </svg>
);

export const ArrowRight = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 6L8.59009 7.41L13.1701 12L8.59009 16.59L10.0001 18L16.0001 12L10.0001 6Z"
      fill="#AED9AA"
    />
  </svg>
)
