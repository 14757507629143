import React, { useState } from "react";

import { Container } from "../../styles/globalStyles";
import styled, { keyframes } from "styled-components";
import { Gradient, H1, H6 } from "../../components/styles/TextStyles";
import Modal from "../Modal";
import { motion } from "framer-motion";

const HomeHero = () => {
  const [isOpen, toggle] = useState(false);

  function handlOpenModal(isOpen) {
    toggle(isOpen);
  }

  return (
    <Hero>
      <ShapeWrapper>
        <Shape />
        <Shape />
        <Shape />
      </ShapeWrapper>

      <HeroWrapper>
        <Container className="container">
          <Title>
            We build <Gradient>AI assistants</Gradient> that automate entire departments.
          </Title>

          <Button
            onClick={() => handlOpenModal(true)}
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.9 }}
          >
            <ButtonIcon src="/images/icons/play.svg" alt="play" />
            <ButtonText>Watch the demo</ButtonText>
          </Button>
        </Container>
      </HeroWrapper>

      <Modal isOpen={isOpen} handleClose={() => handlOpenModal(false)} />
    </Hero>
  );
};

export default HomeHero;

const animationOne = keyframes`
  0% { transform: translate(0%,0%);}
  25% { transform: translate(20%,20%);}
  50% { transform: translate(40%,-20%);}
  75% { transform: translate(0%,-20%);}
  100% { transform: translate(0%,0%);}
`;

const animationTwo = keyframes`
  0% { transform: translate(0%,0%);}
  25% { transform: translate(20%,0%);}
  50% { transform: translate(25%,-20%);}
  75% { transform: translate(0%,-25%);}
  100% { transform: translate(0%,0%);}
`;

const animationThree = keyframes`
  0% { transform: translate(0%,0%);}
  25% { transform: translate(-20%,-20%);}
  50% { transform: translate(-40%,20%);}
  75% { transform: translate(0%,20%);}
  100% { transform: translate(0%,0%);}
`;

const Hero = styled.div`
  position: relative;
  height: 90vh;
  overflow: hidden;
`;

const HeroWrapper = styled.div`
  position: relative;
  height: 100%;
  /* background-color:rgba(255,255,255,.2); */
  background-color: rgba(8, 13, 14, 0.5);
  backdrop-filter: blur (40px);
  -webkit-backdrop-filter: blur (40px);
  z-index: 10;

  .container {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    z-index: 50;
  }

  &:after {
    position: absolute;
    bottom: 0;
    content: "";
    width: 100%;
    height: 50vh;
    background: linear-gradient(0deg, #080d0e 0%, rgba(8, 13, 14, 0) 100%);
    z-index: 40;
  }
`;

const Title = styled(H1)`
  max-width: 92rem;
  margin: 0 auto;
  text-align: center;

  ${Gradient} {
    padding-left: 2px;
  }
`;

const ShapeWrapper = styled.div`
  position: absolute;
  width: 100%;
  /* height:100%; */
  z-index: 1;
  left: 50%;
  top: 25%;
  transform: translate(-50%, 50%);

  > * {
    position: absolute;
    display: block;
    height: 50vh;
    width: 50vh;
    opacity: 0.7;
    border-radius: 50%;
    filter: blur(150px);

    :nth-child(1) {
      left: 20%;
      background-color: #aed9aa;
      animation: ${animationOne} 10s 0s infinite;
    }

    :nth-child(2) {
      left: 40%;
      background-color: #5bb399;
      animation: ${animationTwo} 10s 0s infinite;
    }
    :nth-child(3) {
      left: 60%;
      background-color: #4e878e;
      animation: ${animationThree} 10s 0s infinite;
    }
  }
  /* opacity:.2; */
`;

const Shape = styled.div``;

const Button = styled(motion.button)`
  display:inline-flex;
  align-items:center;
  /* max-width: 25rem; */
  /* width: 100%; */
  margin: 0 auto;
  margin-top: 8rem;
  background-color: #aed9aa;
  border: none;
  border-radius: 1rem;
  padding: 1.2rem 2.4rem;
  text-align: left;
  box-shadow: 0px 10px 40px rgba(174, 217, 170, 0.2);
  transition: box-shadow 0.25s ease-in-out;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 10px 50px rgba(174, 217, 170, 0.3);
  }
`;

const ButtonIcon = styled.img``;

const ButtonText = styled(H6)`
  color: #1f3639;
  font-size: 1.4rem;
  margin-left:10px;
`;
