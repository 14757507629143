import React from "react";
import styled, { keyframes } from "styled-components";
// import { motion } from "framer-motion"

import { H2, H5, H6, Gradient } from "../styles/TextStyles";
import { Container, device } from "../../styles/globalStyles";
import { digitalizationData } from "../../data/homePageData";

const Digitalization = () => {
  return (
    <DigitalizationSection>
      <Container inner>
        <Title>
          <Gradient>Digitalization</Gradient> over years
        </Title>

        <Wrapper>
          <DigiWrapper>
            {digitalizationData.map((item, index) => (
              <YearItem item={item} key={index}>
                <YearTitle uppercase>{item.year}</YearTitle>
                <Point id={item.year.toLowerCase()} />
              </YearItem>
            ))}
            <Line />
          </DigiWrapper>


          <DigiWrapper>
            {digitalizationData.map((item, index) => (
              <Card item={item} key={index}>
                <CardIcon>
                  <img src={item.image} alt={item.title} />
                </CardIcon>
                <CardTitle>{item.title}</CardTitle>
              </Card>
            ))}
          </DigiWrapper>
        </Wrapper>
      </Container>
    </DigitalizationSection>
  );
};

export default Digitalization;

const pulse = keyframes`
  0% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(174, 217, 170, 0.7);
	}

	
    70% {
		transform: scale(1.1);
		box-shadow: 0 0 0 10px rgba(174, 217, 170, 0);
	}

	100% {
		transform: scale(0.8);
		box-shadow: 0 0 0 0 rgba(174, 217, 170, 0);
	}
`;

const DigitalizationSection = styled.section`
  padding-bottom: 16rem;
`;

// Title
const Title = styled(H2)`
  margin-bottom: 6rem;
`;

const Wrapper = styled.div`

@media ${device.tablet} {
    display:grid;
    grid-template-columns:auto 1fr;
    grid-column-gap:4rem;
  }

`

const DigiWrapper = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-column-gap: 4rem;

  @media ${device.tabletL} {
    grid-column-gap: 2rem;
  }

  @media ${device.tablet} {
    grid-template-columns:1fr;
    grid-row-gap: 2rem;
  }

  & {
    :nth-of-type(1) {
      margin-bottom: 4rem;

      @media ${device.tablet} {
        margin-bottom: 0;
      }
    }

    
  }
`;

const YearTitle = styled(H5)`
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 1rem;

 

  @media ${device.tablet} {
    margin-bottom: 0;
    margin-left:1rem;
  }
`;

const Point = styled.div`
  position: relative;
  display: block;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  background-color: #aed9aa;
  margin: 0 auto;
  z-index: 10;

  @media ${device.tablet} {
    order:-1;
    margin: 0;
  }
`;

const Line = styled.div`
  position: absolute;
  width: 85%;
  max-width: 88rem;
  height: 2px;
  left: 50%;
  top: 43px;
  transform: translateX(-50%);
  border: none;
  background: linear-gradient(270deg, #aed9aa 0%, #354a4d 100%);
  z-index: 1;
  @media ${device.tabletL} {
    width: 80%;
  }

  @media ${device.tablet} {
    width:2px;
    height: 80%;
    left: 10%;
    top: 10%;
  }
`;

const YearItem = styled.div`
  justify-self: center;

  @media ${device.tablet} {
    width:100%;
    display:flex;
    align-items:center;
    /* justify-content:space-between; */
  }

  #today {
    z-index: 10;
    /* animation: ${pulse} 2s 0.5s infinite; */
    &:before,
    &:after {
      position: absolute;
      top: 0;
      left: 0;
      content: "";
      display: block;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      z-index: 1;
    }
    &:before {
      animation: ${pulse} 2s 0.5s infinite;
    }
    &:after {
      animation: ${pulse} 2s 1s infinite;
    }
  }
`;

const CardIcon = styled.div`
  padding: 4rem;
  background-color: rgba(255, 255, 255, 0.05);
  box-shadow: inset 0px 0px 2px rgba(255, 255, 255, 0.5);
  border-radius: 1.6rem;
  backdrop-filter: blur (40px);
  -webkit-backdrop-filter: blur (40px);

  img {
    height: 7rem;
    margin: 0 auto;
  }

  @media ${device.tablet} {
    padding: 2rem;

    @media ${device.mobileM} {
      padding: 1.6rem;
    }

    img {
      height: 6rem;
      margin: 0 auto;
      @media ${device.mobileM} {
        height: 4.8rem;
    }
    }
  }
`;

const Card = styled.div`

@media ${device.tablet} {
    display:grid;
    grid-template-columns:auto 1fr;
    grid-column-gap:2rem;
  }

`;

const CardTitle = styled(H6)`
  text-align: center;
  margin-top: 2rem;

  @media ${device.tablet} {
    text-align: left;
    margin-top: 0;
    align-self:center;
  }
`;
