import React from "react";
import { Container, device } from "../styles/globalStyles";
import { Gradient, H2, P } from "../components/styles/TextStyles";
import styled from "styled-components";
import Button from "./Button";




const Cta = () => {


  return (
    <CtaSection>
      <Container inner>
        <Content>
          <Title>
            Transform your customer <Gradient>experience</Gradient>
          </Title>
          <Subtitle large>
            Typically our clients automate 77% of all online interactions with customers, and notice an overall boost in sales of the products and services they offer through the assistant.
          </Subtitle>
        </Content>
        <Button text="Schedule a Meeting" />
      </Container>
    </CtaSection>
  );
};

export default Cta;

const CtaSection = styled.section`
  padding: 16rem 0;
  background: linear-gradient(180deg, #080d0e 0%, #0f1b1c 100%);
  ${Container} {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-items: flex-end;
    align-items: flex-end;

    @media ${device.tablet} {
      grid-template-columns:1fr;
      justify-items: flex-start;
    }
  }
`;

const Content = styled.div`
  @media ${device.tablet} {
      margin-bottom:4rem;
    }
`;

const Title = styled(H2)`
  max-width: 62rem;
  margin-bottom: 2.4rem;
`;

const Subtitle = styled(P)`
max-width:56rem;
`;
