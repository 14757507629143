// import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";
import { ArrowRight } from "../../assets/svg/icons";
// import { H2, H5, H6, P } from "../styles/TextStyles";
import { Container, device } from "../../styles/globalStyles";
import { H5, P } from "../styles/TextStyles";

const Testimonial = ({ showButton }) => {
  return (

    <TestimonialSection>

      <Container inner>

        <TestimonialContent>

          <Image>
            <img
              src="/images/borche-gjorgjeski-rakovoditel-vo-kontakt-centarot-na-a1-makedonija.jpg"
              alt="Borche Gjorgjeski rakovoditel vo kontakt centarot na a1 makedonija"
            />
          </Image>

          <Content>

            <Paragraph>
              "Many users believe that Slavcho is a real human agent. They trust
              him and often are asking to speak directly with him when they are
              calling the Contact Center"
            </Paragraph>

            <Block>
              <BlockContent>
                <Name>BORCHE GJORGJESKI</Name>
                <Position small>Head of Contact Center at</Position>
                <img
                  src="/images/a1-telekom-group@2x.png"
                  alt="Borche Gjorgjeski rakovoditel vo kontakt centarot na a1 makedonija"
                />
              </BlockContent>
              {/* {showButton && (
                <ButtonLink to="/slavcho" className="button is-link has-icon">
                  View Case Studie
                  <ArrowRight />
                </ButtonLink>
              )} */}
              {showButton && (
                <ButtonLink href="https://www.youtube.com/watch?v=ptYzTZB8v9g" target="_blank" className="button is-link has-icon">
                  View Case study
                  <ArrowRight />
                </ButtonLink>
              )}

            </Block>
          </Content>
        </TestimonialContent>
      </Container>
    </TestimonialSection>
  );
};

export default Testimonial;

const TestimonialSection = styled.section`
  position: relative;
  z-index: 20;
  /* padding-bottom: 16rem; */

  ${Container} {
    position: relative;

    &::before {
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-65%);
      display: block;
      content: "";
      width: 20vw;
      height: 20vw;
      background: radial-gradient(#4e878e, #080d0e);
      border-radius: 50%;
      backdrop-filter: blur(100px);
      filter: blur(100px);
      opacity: 0.5;

      z-index: 1;
    }
  }
`;
const TestimonialContent = styled.div`
  position: relative;
  z-index: 100;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 2rem;

  @media ${device.tablet} {
    grid-template-columns:1fr;
    }

`;
const Paragraph = styled.p`
  font-size: 3.2rem;
  line-height: 4.8rem;
  max-width: 88rem;
`;

const Block = styled.div`
  display:grid;
  grid-template-columns:1fr auto;
  margin-top:4rem;
`;
const BlockContent = styled.div`
  img{
    max-width:12rem;
  }
`;

const Image = styled.figure`
  position: relative;
  top:1rem;
  width: 9rem;
  height: 9rem;
  border-radius: 50%;
  overflow: hidden;

  @media ${device.tablet} {
    top:0;
    margin-bottom:3.2rem;
    }
`;

const Content = styled.div``;

const Name = styled(H5)``;
const Position = styled(P)`
  margin-bottom: 1rem;
`;

const ButtonLink = styled.a`
  justify-self:flex-end;
  align-self:flex-start;
`